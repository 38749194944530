import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd2"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd2"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd2");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd2');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd2', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);

  return (
    <div className="exdcon">
 
      <hr />
      <div className='exdtitle'>우리들의 이야기展</div>
      <div className='exddate'>2019-08-09~2019-08-10 루프탑 720</div>
      <div className='funtions'>

        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
        <img src='../../img/z2.jpg' alt="전시 이미지"></img>
      </div>
      <p className='exdexplain'>
        &lt;우리들의 이야기展&gt;을 주최한 '작은 예술가들의 모임 2기'는 총 5명의 청년 작가와
        1명의 기획자로 이루어져 있으며 2018년을 시작으로 총 2번째 전시의 문을 여는 모임이다.
        본 전시는 현시대를 살아가는 20대 청년들이 갖고 있는 고민과 생각들을 캔버스에 
        자유로이 흩뿌릴 수 있도록 기획되었다. 장르에 제한을 두지 않아 소설, 시, 한지공예, 설치미술, 
        수채화 등 다양한 방식으로 전시 공간을 가득 채웠다. 
        다양한 생각과 경험을 소유하며 나아가고 있는 우리들의 모습을 되돌아보기
        위해 ‘우리들의 생각’을 주제로 택한 만큼, 작품을 관람하는 분들에게도 위로와 용기를
        건넬 수 있기를 기대한다. 
      </p>
      <div className='exdbottom'>
        작가 - 김동규, 김필주, 박예슬, 안승빈, 이찬규, 홍세빈 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 루프탑 720<br /><br />
        기간 - 2019-08-09~2019-08-10<br /><br />
      </div>
    </div>
  );
}

export default Exd2;
