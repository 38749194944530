import React, { useEffect } from 'react';
import "../style/Prod.css"

function Prod2() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const images = [
        '../../img/pb1.png',
        '../../img/pb2.png',
        '../../img/pb3.png',
    
       
    ];

    return (
        <div className="con">
            
            <div className="topimg-container">
                <img src="../../img/a2.png" className='topimg' alt="Main Image" />
                <div className="black-overlay"></div>
                <div className='imgtxt'> 라트 X 아트리버스
                    </div>
            </div>

         
            <div className="description">
            <div className='txttitle'>라트X아트리버스</div>
                <div className='txtexplain'>커피 그리고 칵테일과 함께하는 아트리버스의 첫 프로젝트,<br />
                &lt;첫 번째 흐름 : 물에서 땅으로 땅에서 하늘로&gt;에 라트가 어드바이저로 참여했습니다.
</div>
               <div className='datailcon'>
                  <div className='txtdetail'>when. 이슬아 X 뮬리노 에스프레소바 한남점<br />
                  where. 2023. 03. 04. - 2023. 03. 13.   </div>
              
               </div>
            </div>

            {/* 이미지 그리드 */}
            <div className="image-grid">
                {images.map((src, index) => (
                    <img key={index} src={src} alt={`image-${index + 1}`} className="grid-image" />
                ))}
            </div>
        </div>
    );
}

export default Prod2;
