import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd6() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd6"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd6"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd6");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd6');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd6', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);
  return (
    <div className="exdcon">
  
      <hr />
      <div className='exdtitle'>조화展</div>
      <div className='exddate'>2023-04-26~2023-04-30 알지비큐브 갤러리</div>
      <div className='funtions'>
       
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z6.png" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
      이번 전시를 주최한 작은 예술가들의 모임 5기'는 총 10명의 청년 작가와 2개의
      브랜드 그리고 3명의 경영팀으로 이루어져 있으며 2018년을 시작으로 5번째 전시의
      문을 여는 모임이다.

      &lt;조화展&gt;은 지난 &lt;용기展&gt;과 &lt;Plastic Lab展&gt;의 연장선이며 플라스틱 사용에
       대 해 질문하고 답하는 장이다. 
       플라스틱은 양면성을 갖고 있는 존재로 지금 이 순간 에도 누군가를 살리기 위해 사용되지만, 
       누군가에게는 상처와 고통을 주기도 한다.
        이러한 인간이 사용하는 존재들의 양면성은 비단 플라스틱만의 문제가 아니며 
        거시적인 관점에서 우리가 어떻게 환경과 공존할 수 있는가에 대한 고찰을 필요하게 한다.

      &lt;조화展&gt;은 플라스틱의 아름다움과 잔인함을 한 곳에서 표현하며, 
      플라스틱을 비롯한 여러 오염에서 비롯된 환경문제의 양면적인 모습을 다룬다.
       다소 직관적이 고 불쾌할 수 있는 주제이나, 플라스틱 사용으로 발생한 연쇄적인
       문제들을 간접적으로 경험해 봄으로써 향후 우리의 방향성을 함께 고민하고 질문했으면 한다.
      </div>
      <div className='exdbottom'>
        작가 - 강소이, 김도형, 김재용, 박채원, 반아, 안승빈, 이명현, 이혜진,
         류은, DashaGong, JinMin, 살며시, 밀드플레르그리고박준우, 손승재, 이찬규 <br /><br />
        주최/후원 - 라트, 살며시<br /><br />
        장소 - 알지비큐브 갤러리<br /><br />
        기간 - 2023-04-26~2023-04-30<br /><br />
      </div>
    </div>
  );
}

export default Exd6;
