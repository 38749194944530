import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd11() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd11"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd11"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd11");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd11');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd11', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);

  return (
    <div className="exdcon">
   
      <hr />
      <div className='exdtitle'>예술가의 작업실展</div>
      <div className='exddate'>2024-02-20~2024-02-25 갤러리 아미디 한남</div>
      <div className='funtions'>
      
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z11.png" alt="전시 이미지" />

      </div>
      <div className='exdexplain'>
      하나의 예술작품 앞에 서있는 우리는 고작 평균 2초 정도 작품을 관람한다. 허나,
       이러한 예술작품에 담긴 시간들은 헤아릴 수 없는 가치를 지니며 겹겹이 나이테를 형성해왔다.


하나의 예술작품이 호흡을 통해 생명을 얻기까지 예술가는 수없이 붓질을 한다. 
한 번의 붓질엔 예술가의 고민이 담기고 또 한 번의 붓질엔 예술가의 무한한 상상이 담긴다.
예술적 의도와 시도가 담긴 예술 작품은 아름다운 우연으로 인해 관람객과 마주하게 되며 
질문을 던진 후, 답변을 기다린다.

눈앞에 펼쳐진 작업공간은 예술가의 내면 깊숙이 자리 잡은 여러 개의 방 중 하나이며
이 공간에선 예술가의 마음을 몰래 엿볼 수 있다.

본 전시에선 예술가들의 수없이 많은 생각들이 담겨 실존하게 된 작품들을 선보이며
 그 안에 담긴 의미를 재조명한다. 예술가의 붓질을 깊게 파고들어 예술가의 당시 생각과 고민을
  엿보는 과정을 유발하여 예술가의 독자적인 시선이 관람자로 하여금 경계선이 모호해지며 다양한
   생각들이 섞이기 시작하기를 기대한다.
    작품에 담긴 예술가의 생각을 귀와 눈으로 느끼면서 작품과 함께 자유로이 비행하기를.
      </div>
      <div className='exdbottom'>
        작가 - 강지민, 김나양, 김형신, 곰작, 노민정, 박자영, 빈느, 삐삐 조, 와일드멍푸,
         유충식, 이수영, 이수윤, 이엘리, 임상철, 주민, 하도, 하미보, 홍선미, Biegahmoon,
          Rai, Untory그리고문자윤, 박준우, 이찬규 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 갤러리 아미디 한남<br /><br />
        기간 - 2024-02-20~2024-02-25<br /><br />
      </div>
    </div>
  );
}

export default Exd11;
