import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd9() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd9"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd9"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd9");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd9');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd9', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);
  return (
    <div className="exdcon">
    
      <hr />
      <div className='exdtitle'>일렁이는 순간들展</div>
      <div className='exddate'>2023-10-30~11-03 갤러리 라보</div>
      <div className='funtions'>
   
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z9.png" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
       “예술가의 시선과 순간을 담은 작품은 정적인 존재인가, 동적인 존재인가?”


      &lt;일렁이는 순간展&gt;은 예술가가 사유한 순간이 담겨있는 작품들의 생동성을 주목하기
       위하여 기획되었습니다. 전시장에서 마주하며 단순한 시각적 요소들로 받아들이는 작품은 
       정적으로&#40;멈춰있는 순간으로&#41; 인식될 수 있으나, 이러한 작품 속 정적인 순간은 예술가들의
       내면에서 다시금 일렁이며 동적인&#40;생존한&#41;  시간으로 변환됩니다.
       여러분의 마음속에서 현재 마주하고 있는 작품 속 순간들은 일렁이고 있나요?
      </div>
      <div className='exdbottom'>
        작가 - 강변, 검정양말, 김수인, 김연, 류민효, 맹유진, 박수진, 손홍미, 수린, 안지백,
         오경자, 이수빈, 제인영, 하미보, 함두선, celah, JinMin,
          Toonman그리고박준우, 이찬규 <br /><br />

        주최/후원 - 라트<br /><br />
        장소 - 갤러리 라보<br /><br />
        기간 - 2023-10-30~11-03<br /><br />
      </div>
    </div>
  );
}

export default Exd9;
