import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import '../style/customImageSlider.css';

const CustomImagePCSlider = () => {
    const cards = [
        { src: '', content: '내용 1' },
        { src: '', content: '내용 1' },
        { src: '../../img/z1.png', path: '/Exd1' },
        { src: '../../img/z2.jpg', path: '/Exd2' },
        { src: '../../img/z3.jpg', path: '/Exd3' },
        { src: '../../img/z4.jpg', path: '/Exd4' },
        { src: '../../img/z5.png', path: '/Exd5' },
        { src: '../../img/z6.png', path: '/Exd6' },
        { src: '../../img/z7.jpg', path: '/Exd7' },
        { src: '../../img/z8.png', path: '/Exd8' },
        { src: '../../img/z9.png', path: '/Exd9' },
        { src: '../../img/z10.png', path: '/Exd10' },
        { src: '../../img/z11.png', path: '/Exd11' },
        { src: '../../img/z12.png', path: '/Exd12' },
        { src: '../../img/z13.png', path: '/Exd13' },
        { src: '', content: '내용 1' },
        { src: '', content: '내용 1' },
    ];

    const [currentIndex, setCurrentIndex] = useState(4);
    const navigate = useNavigate();

    const handleNext = () => {
        if (currentIndex < cards.length - 3) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 2) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleCardClick = (path) => {
        if (path) {
            navigate(path);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <div {...handlers} className="cslider-container">
        
        <div className="cslider-button left" onClick={handlePrev}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M15 19l-7-7 7-7" />
                </svg>
            </div>
            <div
                className="cslider"
                style={{
                    transform: `translateX(-${(currentIndex - 2) * (365 + 50) + (416 - 365) / 2}px)`,
                    marginLeft: `${4810  + (currentIndex - 4) * 60}px`,}


                }
            >
                {cards.map((card, index) => {
                    const isActive = index === currentIndex;
                    const isPrev = currentIndex - 1 === index;
                    const isNext = currentIndex + 1 === index;
                    const isPrevEnd = currentIndex - 2 === index;
                    const isNextEnd = currentIndex + 2 === index;

                    return (
                        <div
                            key={index}
                            className={`cslider-card 
                                ${isActive ? 'active' : ''} 
                                ${isPrev ? 'prev' : ''} 
                                ${isNext ? 'next' : ''} 
                                ${isPrevEnd ? 'end prev-end' : ''} 
                                ${isNextEnd ? 'end next-end' : ''}`}
                            style={{
                                opacity: isActive || isPrev || isNext || isPrevEnd || isNextEnd ? 1 : 0,
                                width: isPrevEnd || isNextEnd ? '326px' : isActive ? '416px' : '365px',
                                height: isPrevEnd || isNextEnd ? '406px' : isActive ? '520px' : '455px',
                            }}
                            onClick={() => handleCardClick(card.path)}
                        >
                            {card.src ? (
                                <img
                                    src={card.src}
                                    alt={`Card ${index + 1}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    draggable="false"
                                />
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <span>{card.content}</span>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            <div className="cslider-button right" onClick={handleNext}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M9 5l7 7-7 7" />
                </svg>
            </div>
        </div>
    );
};

export default CustomImagePCSlider;
