import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/ImageSlider.css';

const CMobileImageSlider = () => {
    const cards = [
        { src: '../../img/z1.png', path: '/Exd1' },
        { src: '../../img/z2.jpg', path: '/Exd2' },
        { src: '../../img/z3.jpg', path: '/Exd3' },
        { src: '../../img/z4.jpg', path: '/Exd4' },
        { src: '../../img/z5.png', path: '/Exd5' },
        { src: '../../img/z6.png', path: '/Exd6' },
        { src: '../../img/z7.jpg', path: '/Exd7' },
        { src: '../../img/z8.png', path: '/Exd8' },
        { src: '../../img/z9.png', path: '/Exd9' },
        { src: '../../img/z10.png', path: '/Exd10' },
        { src: '../../img/z11.png', path: '/Exd11' },
        { src: '../../img/z12.png', path: '/Exd12' },
        { src: '../../img/z13.png', path: '/Exd13' },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate();

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
    };

    const handleCardClick = (path) => {
        navigate(path);
    };

    return (
        <div className="cmobile-slider-container">
            <div className="cslider-card" onClick={() => handleCardClick(cards[currentIndex].path)}>
                <img 
                    src={cards[currentIndex].src} 
                    alt={`Card ${currentIndex + 1}`} 
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                    draggable="false" 
                />
            </div>

            <div className="cslider-button left" onClick={handlePrev}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M15 19l-7-7 7-7" />
                </svg>
            </div>
            <div className="cslider-button right" onClick={handleNext}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M9 5l7 7-7 7" />
                </svg>
            </div>
        </div>
    );
};

export default CMobileImageSlider;
