import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/Exhipage.css';

const exhibitions = [
  { id: 13, title: 'Oasis展', dateRange: '2024-07-23~2024-08-01', image: '../../img/z13.png' },
  { id: 12, title: '사랑하기 때문에展', dateRange: '2024-04-10~2024-04-15', image: '../../img/z12.png' },
  { id: 11, title: '예술가의 작업실展', dateRange: '2024-02-20~2024-02-25', image: '../../img/z11.png' },
  { id: 10, title: 'Memory of Christmas展', dateRange: '2023-12-18~2023-12-23', image: '../../img/z10.png' },
  { id: 9, title: '일렁이는 순간들展', dateRange: '2023-10-30~11-03', image: '../../img/z9.png' },
  { id: 8, title: '나를 스쳐가는 것들에 대하여展', dateRange: '2023-09-05~2023-09-11', image: '../../img/z8.png' },
  { id: 7, title: 'MY SELF Project展', dateRange: '2023-06-26~2023-07-02', image: '../../img/z7.jpg' },
  { id: 6, title: '조화展', dateRange: '2023-04-26~2023-04-30', image: '../../img/z6.png' },
  { id: 5, title: 'Plastic Lab展', dateRange: '2022-11-17~2022-11-23', image: '../../img/z5.png' },
  { id: 4, title: '용기展', dateRange: '2022-08-19~2022-08-28', image: '../../img/z4.jpg' },
  { id: 3, title: '감정展', dateRange: '2020-11-06~2020-11-09', image: '../../img/z3.jpg' },
  { id: 2, title: '우리들의 이야기展', dateRange: '2019-08-09~2019-08-10', image: '../../img/z2.jpg' },
  { id: 1, title: '길가에 핀 꽃들展', dateRange: '2018-06-23~2018-06-23', image: '../../img/z1.png' }
];

function Exhibitionpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleExhibitionClick = (exhibition) => {
    navigate(`/exd${exhibition.id}`); // 전시 ID를 경로에 추가
  };

  return (
    <div className="exhibition-page">
      <div className="extitle">
        아티스트의 상상이 현실이 될 수 있도록 <br />
        다양한 전시를 기획합니다.
      </div>

      <h2 className="etab">전체 전시</h2>

      <div className="exhibition-grid">
        {exhibitions.map((exhibition) => (
          <div
            key={exhibition.id}
            className="exhibition-item"
            onClick={() => handleExhibitionClick(exhibition)}
          >
            <div
              className="exhibition-image"
              style={{
                backgroundImage: `url(${exhibition.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <div className="exhibition-info">
              <h3>{exhibition.title}</h3>
              <p>{exhibition.dateRange}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Exhibitionpage;
