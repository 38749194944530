import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd5() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd5"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd5"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd5");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd5');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd5', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);
  return (
    <div className="exdcon">

      <hr />
      <div className='exdtitle'>Plastic Lab展</div>
      <div className='exddate'>2022-11-17~2022-11-23 피에스비 커피바</div>
      <div className='funtions'>
    
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">

      <img src="../../img/z5.png" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
      “우리는 무엇을 위해 빠르게 달려만 가는가?”

인류는 빠르게 발전해 왔고 지금도 숨 가쁘게 발전하는 중이다. 
가진 것보다 가질 것을 상상하며, 걸어온 길보다 걸어갈 길을 예측하는 행위가 
더욱 빈번하게 일어나고 있다.
인류는 무엇을 위해 빠르게 달려가고 있으며 인류의 공상은 어디로 향하고 있는 것인가.
 단순히 발전을 위한 수단으로써 플라스틱을 사용하며 편리함이란 가면을 씌워 환경을 해하고
  있는 것이 아닌지에 대한 근원적인 고찰이 필요한 시점이다.
인류의 욕심은 쌓여가는 플라스틱 무덤처럼 무자비하게 커지고만 있는 것이
아닌지에 대해 생각해 보며 인류가 나아가고 있는 방향성에 대해 논의하고자 한다.
      </div>
      <div className='exdbottom'>
        작가 - 강소이, 류은, 박채원, 안승빈, 이명현 그리고 손승재, 이찬규 
        주최/후원 - 라트, 살며시, 피에스비 커피바<br /><br />
        장소 - 피에스비 커피바<br /><br />
        기간 - 2022-11-17~2022-11-23<br /><br />
      </div>
    </div>
  );
}

export default Exd5;
