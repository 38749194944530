import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import '../style/Projectpage.css';

const projectData = {
  '행사기획 운영': [ 
    { id: 4, title: "샐러-드 샵 아트 팝업", date: "2024. 09. 26.", img: "../../img/a4.png" },
    { id: 3, title: "라트X밀드플레르", date: "2023. 04. 26.", img: "../../img/a3.png" },
    { id: 2, title: "라트X아트리버스", date: "2024-02-02", img: "../../img/a2.png" },
    { id: 1, title: "라트X살며시", date: "2022. 11. 17.", img: "../../img/a1.png" },
  ],
  "공간콘텐츠 기획공급": [
    { id: 6, title: "낙서 프로젝트", date: "2023. 11.", img: "../../img/b2.png" },
    { id: 5, title: "라트살롱 프로젝트", date: "2023. 06.", img: "../../img/b1.png" },
  ],
};

const Projectpage = () => {
  const [activeTab, setActiveTab] = useState("all");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderSlider = (category) => {
    const projects = projectData[category];
    if (!projects) return null;
  
    return (
      <div className="concon">
        <h3 
          className="category-title" 
          style={{ 
            maxWidth:"250px",
            textAlign: "left", 
            minWidth: "250px", 
            display: "block" 
          }}
        >
          {category}
        </h3>
        <div className="project-grid">
          {projects.map((project) => (
            <Link to={`/prod${project.id}`} key={project.id} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div>
                <div
                  className="image-background"
                  style={{
                    backgroundImage: `url(${project.img})`,
                  }}
                />
                <div className="project-details">
                  <div className="proti">{project.title}</div>
                  <div className="prodate">{project.date}</div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="procon">
      <div className="expl">
        예술이 일상에 스며들 수 있도록<br />
        다양한 프로젝트를 기획합니다.
      </div>
      <div className="tab-menu">
        <div className={`tab ${activeTab === "all" ? "active" : ""}`} onClick={() => setActiveTab("all")}>
          전체
        </div>
        <div className={`tab ${activeTab === "행사기획 운영" ? "active" : ""}`} onClick={() => setActiveTab("행사기획 운영")}>
          행사기획 운영
        </div>
        <div className={`tab ${activeTab === "공간콘텐츠 기획공급" ? "active" : ""}`} onClick={() => setActiveTab("공간콘텐츠 기획공급")}>
          공간콘텐츠 기획공급
        </div>
      </div>
      
      {/* 선택된 탭에 따라 다른 카테고리 콘텐츠 렌더링 */}
      {activeTab === "all" && (
        <>
          {renderSlider("행사기획 운영")}
          {renderSlider("공간콘텐츠 기획공급")}
        </>
      )}
      {activeTab === "행사기획 운영" && renderSlider("행사기획 운영")}
      {activeTab === "공간콘텐츠 기획공급" && renderSlider("공간콘텐츠 기획공급")}
    </div>
  );
};

export default Projectpage;
