import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd13() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd13"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd13"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd13");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd13');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd13', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);
  return (
    <div className="exdcon">
   
      <hr />
      <div className='exdtitle'>Oasis展</div>
      <div className='exddate'>2024-07-23~2024-08-01 프로타주 갤러리</div>
      <div className='funtions'>
   
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z13.png" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
      현대 사회가 ’피로사회‘라 불린 것도 벌써 오랜 이야기가 되었습니다.
       수많은 부담과 어려움 속에서 살아가는 현대인들에게 스트레스는 일상이 되어버렸죠.

하지만 뜨거운 사막에도 오아시스가 있듯, 지치는 일상 속에도
 우리를 잠시 쉬어가게 해주는 존재를 찾을 수 있습니다.

누군가에겐 가족이, 누군가에겐 취미가, 또 다른 누군가에겐 반려동물이

&lt;Oasis&gt;展은 현대 사회를 살아가며 지친 이들에게 당신이 쉬고 회복할 수 있는 것은
 무엇 덕분인지 묻는다. 각자만의 ’오아시스‘를 깊이 생각해보는 장을 열어 현대인들에게 잠시나마 쉼표를 선물하고자 한다. 
 본 전시를 통해 일상에 지친 이들이 자신의 오아시스를 진지하게 돌아보며 그것을 소중히 여기는 계기가 되길 소망한다.
      </div>
      <div className='exdbottom'>
        작가 - 100intheworld, 4a, 그리는 나비, 김강민, 김나양, 김민, 남미정, 달담, 
        도시일상, 모닝롤프레스, 미확인, 박경수, 박단, 박상욱, 스치우다, 심즈, 양정은, 
        여름하, 오다원, 윤혜신, 응아, 이영서, 이지수, 이효리, 전다영, 전지예, 천주현,
         포코코, 홍선미, 황종빈, 흰 종이에 달이 스치운다, DAMI, dreampowder, HAMIBO,
         Marirosa, MenoMuki, MIKIMOO, wood그리고박준우, 이채은, 이찬규 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 프로타주 갤러리<br /><br />
        기간 - 2024-07-23~2024-08-01<br /><br />
      </div>
    </div>
  );
}

export default Exd13;
