import React, { useEffect, useState } from 'react';
import "../style/font.css";
import "../style/maingo.css";

function Maingo() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // 텍스트 배열 정의
    const mobileTexts = [
        [<div>자체 프로세스를 통해 전시회 기획부터</div>,
        <div>운영까지 모두 진행합니다.</div>,],
     [   <div>커뮤니티를 기획하여 아티스트가 대중들과</div>,
        <div>쉽게 만날 수 있도록 연결합니다.</div>,],
        [<div>아티스트의 작품으로 의류를 제작하고</div>,
        <div>효과적인 브랜딩을 제안합니다.</div>,],
        [<div>다양한 공간들과 제휴를 맺어</div>,
        <div>예술 콘텐츠를 공급합니다.</div>,]
    ];

    const desktopTexts = [
        '자체 프로세스를 통해 전시회 기획부터 운영까지 모두 진행합니다.',
        '커뮤니티를 기획하여 아티스트가 대중들과쉽게 만날 수 있도록 연결합니다.',
        '아티스트의 작품으로 의류를 제작하고 효과적인 브랜딩을 제안합니다. ',
        '다양한 공간들과 제휴를 맺어 예술 콘텐츠를 공급합니다.',
    ];

    // 화면 크기 변경 감지
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // 두 개씩 텍스트를 묶어 렌더링하기 위한 함수
    const renderMobileTexts = () => {
        const result = [];
        for (let i = 0; i < mobileTexts.length; i += 2) {
            result.push(
                <div key={i} style={{ marginBottom: '10px' }}>
                    {mobileTexts[i]}
                    {mobileTexts[i + 1] && <br />}
                    {mobileTexts[i + 1]}
                </div>
            );
        }
        return result;
    };

    return (
        <div className="Maincon">
            <div className="mainbackimg">
                <div>
                    <h1 className="title">신진 아티스트의 페이스 메이커</h1>
                </div>
                <div className="down"></div>
                <div className="downcon">
                    <div className="titleimg"></div>
                    <div className="contentcon">
                        <div className='updownccon'>
                            <div className="upcontent">
                                <div className="mainlogo"></div>
                                <h1 className="hili">라트</h1> <br />
                                <div className="nohili">: 예술 콘텐츠 매니지먼트 컴퍼니</div>
                            </div>
                            <div className="bottomcontent">
                                <div className="white">
                                신진 아티스트가 예술 활동을 유지하기 위해서는 지속적인 창작의 발판인 전시 참여와 대중들과 만날 수 있는 소통의 장이 필요하며, 이러한 콘텐츠가 지속될 수 있도록 돕는 운영 프로세스가 필요합니다. 

라트는 자체 운영 프로세스를 기반으로 다채로운 예술 프로젝트를 기획하고 예술가의 지속가능성을 위해 가장 앞서서 고민하고 시도하는 기업입니다.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="midcon">
                <div className="midcont">
                    {/* 4개의 콘텐츠 항목 */}
                    <div className="midcontents">
                        <img src="../../img/m1.png" className="midimg" alt="전시 기획·운영" />
                        <div className="micontentscon">
                            <div className="white">
                                {isMobile ? mobileTexts[0] : desktopTexts[0]}
                            </div>
                            <div className="color">전시 기획·운영</div>
                        </div>
                    </div>
                    <div className="midcontents">
                        <img src="../../img/m2.png" className="midimg" alt="커뮤니티 기획·운영" />
                        <div className="micontentscon">
                            <div className="white">
                                {isMobile ? mobileTexts[1] : desktopTexts[1]}
                            </div>
                            <div className="color">커뮤니티 기획·운영</div>
                        </div>
                    </div>
                    <div className="midcontents">
                        <img src="../../img/m3.png" className="midimg" alt="IP 개발 | 브랜드 운영" />
                        <div className="micontentscon">
                            <div className="white">
                                {isMobile ? mobileTexts[2] : desktopTexts[2]}
                            </div>
                            <div className="color">IP 개발 | 브랜드 운영</div>
                        </div>
                    </div>
                    <div className="midcontents">
                        <img src="../../img/m4.png" className="midimg" alt="공간 제휴 | 공간 프로그램 기획·운영" />
                        <div className="micontentscon">
                            <div className="white">
                                {isMobile ? mobileTexts[3] : desktopTexts[3]}
                            </div>
                            <div className="color">공간 제휴 | 공간 프로그램 기획·운영</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Maingo;
