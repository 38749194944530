import React, { useEffect } from 'react';

import "../style/Prod.css"

function Prod3() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const images = [
        '../../img/pc1.png',
        '../../img/pc2.png',
        '../../img/pc3.png',
        '../../img/pc4.png',
        '../../img/pc5.png',
        '../../img/pc6.png',
       
    ];

    return (
        <div className="con">
            
            <div className="topimg-container">
                <img src="../../img/a3.png" className='topimg' alt="Main Image" />
                <div className="black-overlay"></div>
                <div className='imgtxt'>조화展
                </div>
            </div>

         
            <div className="description">
                <div className='txttitle'>라트X밀드플레르</div>
                <div className='txtexplain'>친환경 코스메틱 브랜드 밀드플레르의 첫 번째 팝업 전시에 라트가 <br></br>전시기획으로 참여하여 공간을 기획했습니다.</div>
               <div className='datailcon'>
                  <div className='txtdetail'>play with. 밀드플레르   </div>
                  <div className='txtdetail'>장소-
                  갤러리 알지비큐브   </div>
                  <div className='txtdetail'>기간-
                  2023. 04. 26. - 04. 30.   </div>
               </div>
            </div>

            {/* 이미지 그리드 */}
            <div className="image-grid">
                {images.map((src, index) => (
                    <img key={index} src={src} alt={`image-${index + 1}`} className="grid-image" />
                ))}
            </div>
        </div>
    );
}

export default Prod3;
