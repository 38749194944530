import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/ImageSlider.css';

const MobileImageSlider = () => {
    const cards = [
        { src: '../../img/a1.png', path: '/Prod1' },
        { src: '../../img/a2.png', path: '/Prod2' },
        { src: '../../img/a3.png', path: '/Prod3' },
        { src: '../../img/a4.png', path: '/Prod4' },
        { src: '../../img/b1.png', path: '/Prod5' },
        { src: '../../img/b2.png', path: '/Prod6' },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate();

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
    };

    const handleCardClick = (path) => {
        navigate(path);
    };

    return (
        <div className="mobile-slider-container">
            <div className="slider-card" onClick={() => handleCardClick(cards[currentIndex].path)}>
                <img 
                    src={cards[currentIndex].src} 
                    alt={`Card ${currentIndex + 1}`} 
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                    draggable="false" 
                />
          
            </div>

            <div className="slider-button left" onClick={handlePrev}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M15 19l-7-7 7-7" />
                </svg>
            </div>
            <div className="slider-button right" onClick={handleNext}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M9 5l7 7-7 7" />
                </svg>
            </div>
         
        </div>
    );
};

export default MobileImageSlider;
