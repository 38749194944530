import React from "react";
import "../style/mainbottom.css";

function Mainbottom() {
    // 화면을 맨 위로 스크롤하는 함수
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // 부드럽게 스크롤
        });
    };

    return (
        <div className="con">
            {/* 클릭 시 scrollToTop 함수 호출 */}
            <div className="barrow" onClick={scrollToTop}></div>
            <div className="arroword" onClick={scrollToTop}>TOP</div>

            <div className="logo"></div>
            <div className="copy">© LART ALL RIGHT RESERVED</div>
            <div className="name">
                대표자. 이찬규 | 사업자등록번호. 874-21-01991<br />
                서울특별시 서대문구 신촌로 25, 2층 2574호(창천동)<br />
                e-mail. lartofficial0@gmail.com
            </div>
        </div>
    );
}

export default Mainbottom;
