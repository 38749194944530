import React, { useEffect } from 'react';

import "../style/Prod.css"

function Prod6() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const images = [
        '../../img/pf1.png',
        '../../img/pf2.png',
        '../../img/pf3.png',
        '../../img/pf4.png',
     
       
    ];

    return (
        <div className="con">
            
            <div className="topimg-container">
                <img src="../../img/b2.png" className='topimg' alt="Main Image" />
                <div className="black-overlay"></div>
                <div className='imgtxt'>낙서 프로젝트</div>
            </div>

         
            <div className="description">
                <div className='txttitle'>낙서 프로젝트</div>
                <div className='txtexplain'>우리는 때로 사소한 낙서를 통해 영감을 받기도 합니다. <br/>
                 저희가 작은 캔버스를 준비해둘게요.  <br/>
                 원하시는 도구만 챙겨오셔서 함께 낙서해 보실래요?</div>
               <div className='datailcon'>
                  
                  <div className='txtdetail'>장소-
                  갤러리 아미디 아현   </div>
                  <div className='txtdetail'>기간-
                  2023. 11. ~ 2023. 12   </div>
               </div>
            </div>

            {/* 이미지 그리드 */}
            <div className="image-grid">
                {images.map((src, index) => (
                    <img key={index} src={src} alt={`image-${index + 1}`} className="grid-image" />
                ))}
            </div>
        </div>
    );
}

export default Prod6;
