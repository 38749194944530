import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import '../style/ImageSlider.css';

const DesktopImageSlider = () => {
    const cards = [
        { src: '', content: '' },
        { src: '../../img/a1.png', path: '/Prod2' },
        { src: '../../img/a2.png', path: '/Prod3' },
        { src: '../../img/a3.png', path: '/Prod4' },
        { src: '../../img/a4.png', path: '/Prod5' },
        { src: '../../img/b1.png', path: '/Prod6' },
        { src: '', content: '' },
    ];
    React.useEffect(() => {
        document.body.style.overflowX = 'hidden';
        return () => {
            document.body.style.overflowX = 'auto';
        };
    }, []);
    const [currentIndex, setCurrentIndex] = useState(2);
    const [fadeOutIndex, setFadeOutIndex] = useState(null);

    const navigate = useNavigate();

    const handleNext = () => {
        if (currentIndex < cards.length - 2) {
            setFadeOutIndex(currentIndex);
            setCurrentIndex((prevIndex) => prevIndex + 1);

            setTimeout(() => setFadeOutIndex(null), 500);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 1) {
            setFadeOutIndex(currentIndex);
            setCurrentIndex((prevIndex) => prevIndex - 1);

            setTimeout(() => setFadeOutIndex(null), 500);
        }
    };

    const handleCardClick = (path) => {
        navigate(path);
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <div {...handlers} className="slider-container">
            <div
                className="slider"
                style={{
                    transform: `translateX(-${currentIndex * (368)}px)`,
               
                }}
            >
                {cards.map((card, index) => {
                    const isActive = index === currentIndex;
                    const isPrev = (currentIndex - 1) === index;
                    const isNext = (currentIndex + 1) === index;

                    return (
                        <div
                            key={index}
                            className={`slider-card ${isActive ? 'active' : ''} ${isPrev ? 'prev' : ''} ${isNext ? 'next' : ''} ${fadeOutIndex === index ? 'fade-out' : ''}`}
                            style={{
                                opacity: isActive ? 1 : isPrev || isNext ? 1 : 0,
                            }}
                            onClick={() => handleCardClick(card.path)}
                        >
                            {card.src ? (
                                <img 
                                    src={card.src} 
                                    alt={`Card ${index + 1}`} 
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                    draggable="false" 
                                />
                            ) : (
                                <div className="empty-card">
                                    <span>{card.content}</span>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            <div className="slider-button left" onClick={handlePrev}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M15 19l-7-7 7-7" />
                </svg>
            </div>
            <div className="slider-button right" onClick={handleNext}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M9 5l7 7-7 7" />
                </svg>
            </div>
        </div>
    );
};

export default DesktopImageSlider;
