import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd10() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd10"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd10"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd10");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd10');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd10', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);
  return (
    <div className="exdcon">
      
      <hr />
      <div className='exdtitle'>Memory of Christmas展</div>
      <div className='exddate'>2023-12-18~2023-12-23 갤러리 드플로허
      </div>
      <div className='funtions'>
 
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z10.png" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
      “어릴 적 크리스마스는 정말 설레고 기다려지는 날이었습니다.
선물도 받고, 가족과 함께 저녁을 먹으며 크리스마스 노래를 부르는, 일 년 중 가장 따뜻한 
날이었죠. 사랑하는 사람과 선물을 교환하기도, 학교에선 크리스마스 씰을 사기도 했습니다.
요즘은 세상이 너무 바빠진 탓인지, 가게마다 걸어 놓던 빛나는 전구 트리도, 길거리에
울려 퍼지던 캐럴도 전보다 옅게 느껴지는 것 같아 참 아쉽습니다.
지난 기억을 되살려 보며, 당신에게 크리스마스는 어떤 의미인가요? 크리스마스의 따뜻함이 
다시금 불을 지피길 소망합니다.”

&#91;크리스마스 특별전&#93;은 희미해져 가는 크리스마스의 의미를 되새기고, 그 따뜻함을 다시 
전하기 위해 기획되었다. 크리스마스의 추억을 회상하며, 그날의 본질을 조명하고자 한다. 
관객과 함께 소통하는 참여형 전시를 통해 이곳에 온 모두가 지난날 각자의 의미로 간직한
 크리스마스의 기억, 그 작은 불씨를 다시금 지피길 기대한다.
      </div>
      <div className='exdbottom'>
        작가 - 강소이, 강진주, 김진희, 반아, 샬롬유, 수린, 심세희,
         양윤자, 응아, 이수영, 임수지, 조성경 그리고 박준우, 수린, 이찬규 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 갤러리 드플로허<br /><br />
        기간 - 2023-12-18~2023-12-23<br /><br />
      </div>
    </div>
  );
}

export default Exd10;
