import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd12() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd12"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd12"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd12");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd12');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd12', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);
  return (
    <div className="exdcon">
   
      <hr />
      <div className='exdtitle'>사랑하기 때문에展</div>
      <div className='exddate'>2024-04-10~2024-04-15 인영갤러리</div>
      <div className='funtions'>

        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z12.png" alt="전시 이미지" />
        
      </div>
      <div className='exdexplain'>
      “우리는 ’사랑‘이라 하면 연인 간의 뜨거운 사랑 만을 떠올리곤 합니다. 그러나 사랑은 보다
       근본적인 의미를 갖는 것 같습니다.

동생을 위해 사다 주는 약 한 봉지,
바른 길로 가길 바라는 엄마의 따끔한 꾸지람,
실수를 범한 친구에게 기꺼이 내미는 용서의 악수 등

사랑하기 때문에 하는 일들이, 사랑이라 부를 수 있는 일들이 우리 주변에 많이 있습니다.
이번 전시를 통해 각자의 사랑을 이야기하며 퍼즐을 맞추듯 사랑의 의미를 찾아가길 바랍니다.”

&#91;사랑하기 때문에 展&#93;을 통해 놓쳤던 사랑의 의미를 다시 조명해 보고자 하며,
더 나아가 그 사랑을 일상에서 다시 한번 실천할 힘을 얻어갈 수 있길 바란다.<br />
      </div>
      <div className='exdbottom'>
        작가 - 강진주, 곰작, 반아, 안승빈, 응아, 이문자, 이수빈, 이정은,
         임상철, 조성경, 최진숙그리고 박준우, 이채은, 이찬규 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 인영갤러리<br /><br />
        기간 - 2024-04-10~2024-04-15<br /><br />
      </div>
    </div>
  );
}

export default Exd12;
