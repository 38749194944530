import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import './style/font.css';
import Topnav from './pages/Topnav';
import Maingo from './pages/Maingo';
import ImageSlider from './pages/ImageSlider';
import Mainbottom from './pages/mainbottom';
import Projectpage from './pages/Projectpage';
import Exhibitionpage from './pages/Exhipage';
import Exd1 from './pages/Exd1';
import Exd2 from './pages/Exd2';
import Exd3 from './pages/Exd3';
import Exd4 from './pages/Exd4';
import Exd5 from './pages/Exd5';
import Exd6 from './pages/Exd6';
import Exd7 from './pages/Exd7';
import Exd8 from './pages/Exd8';
import Exd9 from './pages/Exd9';
import Exd10 from './pages/Exd10';
import Exd11 from './pages/Exd11';
import Exd12 from './pages/Exd12';
import Exd13 from './pages/Exd13';
import Prod1 from './pages/Prod1';
import Prod2 from './pages/Prod2';
import Prod3 from './pages/Prod3';
import Prod4 from './pages/Prod4';
import Prod5 from './pages/Prod5';
import Prod6 from './pages/Prod6';
import { analytics } from './Config';
import { logEvent } from 'firebase/analytics';
import ResponsiveImageSlider from './pages/CustomImageSlider';


function App() {
  const location = useLocation();
  const [showImageSlider, setShowImageSlider] = useState(true);

  useEffect(() => {
  
    const hiddenSliderPages = [
      '/exhibition', '/project', '/exd1', '/exd2', '/exd3', '/exd4', 
      '/exd5', '/exd6', '/exd7', '/exd8', '/exd9', '/exd10', 
      '/exd11', '/exd12', '/exd13', '/prod1', '/prod2', 
      '/prod3', '/prod4', '/prod5', '/prod6'
    ];

 
    setShowImageSlider(!hiddenSliderPages.includes(location.pathname));
  }, [location]);

  return (
    <div className="App">
      <Helmet>
        <title>Lart</title>
        <meta name="description" content="전시 및 기획 협업" />
        <meta property="og:image" content="https://i.ibb.co/J22pNC2/1.png" />
      </Helmet>
     
      <Topnav />
      <Content />


      {showImageSlider && (
        <>
          <ImageSlider />
          <ResponsiveImageSlider />
        </>
      )}

      <Mainbottom /> 
    </div>
  );
}

function Content() {
  return (
    <Routes>
      <Route path="/" element={<Maingo />} />
      <Route path="/about" element={<Maingo />} />
      <Route path="/project" element={<Projectpage />} />
      <Route path="/exhibition" element={<Exhibitionpage />} />
      <Route path="/exd1" element={<Exd1 />} />
      <Route path="/exd2" element={<Exd2 />} />
      <Route path="/exd3" element={<Exd3 />} />
      <Route path="/exd4" element={<Exd4 />} />
      <Route path="/exd5" element={<Exd5 />} />
      <Route path="/exd6" element={<Exd6 />} />
      <Route path="/exd7" element={<Exd7 />} />
      <Route path="/exd8" element={<Exd8 />} />
      <Route path="/exd9" element={<Exd9 />} />
      <Route path="/exd10" element={<Exd10 />} />
      <Route path="/exd11" element={<Exd11 />} />
      <Route path="/exd12" element={<Exd12 />} />
      <Route path="/exd13" element={<Exd13 />} />
      <Route path="/prod1" element={<Prod1 />} />
      <Route path="/prod2" element={<Prod2 />} />
      <Route path="/prod3" element={<Prod3 />} />
      <Route path="/prod4" element={<Prod4 />} />
      <Route path="/prod5" element={<Prod5 />} />
      <Route path="/prod6" element={<Prod6 />} />
    </Routes>
  );
}

export default App;
