import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd7() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd7"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd7"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd7");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd7');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd7', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);

  return (
    <div className="exdcon">

      <hr />
      <div className='exdtitle'>MY SELF Project展</div>
      <div className='exddate'>2023-06-26~2023-07-02 갤러리 아미디 신촌</div>
      <div className='funtions'>

        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z7.jpg" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
      MYSELF Project는 특정 주제에 제한받지 않고 순수하게 피어난 영감으로 인해 
      창작된 작품들을 소개하기 위하여 기획되었다. 
      작품 안에 담긴 작가의 정체성을 드러내는 요소들에 주목하며,
      전시공간 안에서의 공명을 일으키고자 한다.

MY·SELF는 화자나 필자가 행동의 대상이 되는 경우, 자신을 의미하는 단어로 사용된다. 
그림에서의 MY·SELF는 작가는 사랑하는 색채, 선 그리고 면으로 치환되어 상호작용을 이끌어낸다.
 작가는 그림을 그리며 물감에 고민을 섞고, 붓에 생각을 담아 사유의 시간을 거쳐 하나의 작품을
  세상에 실존하게 만든다. 이러한 작품은 작가 자신을 대변하여 무성&#40;無聲&#41;의 형태로
   관람객에게 질문을 던지고 답을 갈망한다.
   마주하고 있는 작품이 어떤 생각을 담고 혹은 닮고 있는지를 바라보며
   작가의 정체성을 온전히 경험하는 시간이 되기를 바란다.
      </div>
      <div className='exdbottom'>
        작가 - 룡보이, 류은, 모하마드 아민, 박결, 박연우, 박진아, 박종희, 
        아르스트리, 안승빈, 엠엠, 이명현, 이해원, 장지혜, 주테일, 토찌, 피넛잼,
         황유, ewell, JinMin그리고박준우, 손승재, 이찬규 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 갤러리 아미디 신촌<br /><br />
        기간 - 2023-06-26~2023-07-02<br /><br />
      </div>
    </div>
  );
}

export default Exd7;
