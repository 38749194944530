import React, { useEffect } from 'react';
import "../style/Prod.css"

function Prod5() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const images = [
        '../../img/pe1.png',
        '../../img/pe2.png',
        '../../img/pe3.png',
        '../../img/pe4.png',

       
    ];

    return (
        <div className="con">
            
            <div className="topimg-container">
                <img src="../../img/b1.png" className='topimg' alt="Main Image" />
                <div className="black-overlay"></div>
                <div className='imgtxt'>LART<br/> SALON</div>
            </div>

         
            <div className="description">
                <div className='txttitle'>라트살롱 프로젝트</div>
                <div className='txtexplain'>
                작품 활동을 하며 자연스레 생기는 고민들에 대해 작가들과 소통하며 <br></br>위안을 받고 이를 넘어서 새로운 영감을 얻을 수 있는 커뮤니티 프로젝트.   </div>
               <div className='datailcon'>
                
                  <div className='txtdetail'>장소-
                  갤러리 아미디 아현   </div>
                  <div className='txtdetail'>기간-
                  2023. 06. ~ 2023. 08.   </div>
               </div>
            </div>

            {/* 이미지 그리드 */}
            <div className="image-grid">
                {images.map((src, index) => (
                    <img key={index} src={src} alt={`image-${index + 1}`} className="grid-image" />
                ))}
            </div>
        </div>
    );
}

export default Prod5;
