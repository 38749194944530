import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd4() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd4"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd4"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd4");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd4');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd4', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);

  return (
    <div className="exdcon">
   
      <hr />
      <div className='exdtitle'>용기展</div>
      <div className='exddate'>2022-08-19~2022-08-28 갤러리 드플로허</div>
      <div className='funtions'>
 
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z4.jpg" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
      이번 전시를 주최한 '작은 예술가들의 모임 4기'는 총 5명의 청년 작가와 3명의 경영팀으로 이루어져 있으며 
      <br />2018년을 시작으로 총 4번째 전시의 문을 여는 모임이다.

길을 걸어가다 무질서한 분리수거 현장을 본 적이 있다. 
플라스틱뿐만 아니라 많은 쓰레기들이 무분별하게 흩뿌려져 있었다. 
누군가는 주위에서 쉽게 볼 수 있는 플라스틱의 무덤을 보고도 무관심에 휩싸여 
스쳐 지나갔고 누군가는 무덤의 크기를 키우는 행위에 일조하였다.
 '그렇다면 누군가는 플라스틱 무덤의 심각성에 대한 고찰과 사유를 통해 무덤의
  존재를 알려야 하지 않을까'하는 자그마한 생각에서 용기展이 발아했다.

용기展은 편리함에 취해 혹은 변화하는 환경 속에서 불가피한 적응을 택하기 위해
무심코 사용해 왔던 플라스틱에 대해 논한다. 다소 직관적일 수 있고 불쾌할 수 있는 주제이다. 
그러나 현실에 존재하는 문제를 단순한 감정에 의해 수동적인 행태로 덮어두기만 한다면 
모든 문제는 염증으로 변해 곪아 터져버릴 것이다. 이번 전시를 통해 플라스틱 사용으로
 발생한 연쇄적인 문제들을 간접적으로 경험해 봄으로써 현대 사회의 모습과 
 삶의 방식을 성찰해 볼 수 있는 기회가 되기를 바란다. 
 또한 5명의 청년작가들과 2명의 경영팀원들의 작은 용기가 발화할 수 있기를 고대한다.
      </div>
      <div className='exdbottom'>
        작가 - 강소이, 류은, 박채원, 안승빈, 이명현 그리고 손승재, 이찬규 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 갤러리 드플로허<br /><br />
        기간 - 2022-08-19~2022-08-28<br /><br />
      </div>
    </div>
  );
}

export default Exd4;
