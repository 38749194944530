import React, { useEffect } from 'react';
import "../style/Prod.css"

function Prod1() {
    useEffect(() => {
        window.scrollTo(0, 0);
   
      }, []);
    const images = [
        '../../img/pa1.png',
        '../../img/pa2.png',
        '../../img/pa3.png',
        '../../img/pa4.png',
        '../../img/pa5.png',
        '../../img/pa6.png',
       
    ];

    return (
        <div className="con">
            
            <div className="topimg-container">
                <img src="../../img/a1.png" className='topimg' alt="Main Image" />
                <div className="black-overlay"></div>
                <div className='imgtxt'> PLASTIC<br />
PLANTS<br />
조화 
                    </div>
            </div>

         
            <div className="description">
            <div className='txttitle'>라트X살며시</div>
                <div className='txtexplain'>친환경 라이프스타일 브랜드 
                    살며시의 첫 번째 팝업 전시에 라트가 전시기획으로 참여하여 
                    공간을 기획했습니다.</div>
               <div className='datailcon'>
                  <div className='txtdetail'>play with-살며시   </div>
                  <div className='txtdetail'>장소-피에스비 커피바
                   </div>
                  <div className='txtdetail'>기간-
                  2022. 11. 17. - 11. 23.  </div>
               </div>
            </div>

            {/* 이미지 그리드 */}
            <div className="image-grid">
                {images.map((src, index) => (
                    <img key={index} src={src} alt={`image-${index + 1}`} className="grid-image" />
                ))}
            </div>
        </div>
    );
}

export default Prod1;
