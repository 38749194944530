import React, { useEffect } from 'react';
import "../style/Prod.css"

function Prod4() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const images = [ '../../img/pd6.png',
          '../../img/pd2.png',
        '../../img/pd3.png',
        '../../img/pd4.png',
        '../../img/pd5.png',
       
        '../../img/pd1.png',
       
    ];

    return (
        <div className="con">
            
            <div className="topimg-container">
                <img src="../../img/a4.png" className='topimg' alt="Main Image" />
                <div className="black-overlay"></div>
                <div className='imgtxt'>Selle-d Shop-!</div>
            </div>

         
            <div className="description">
                <div className='txttitle'>샐러-드 샵 아트 팝업 </div>
                <div className='txtexplain'>샐러-드 샵은 알록달록 조화로운 샐러드처럼 다양한 분야의 예술이<br/> 
                모여 화를 이룰 수 있도록 기획한 아트 팝업입니다. <br/> <br/> 
                가장 상업적인 동네, 성수에서 예술 콘텐츠로 공간을 채워 기존 일러스트 페어 형식의 부스 운영과<br/> 
                 더불어 한쪽 벽은 ’오직 그림‘으로만 채우며 이번 아트 굿즈 팝업 ’샐러-드 샵‘을 준비했습니다.</div>
               <div className='datailcon'>
                  <div className='txtdetail'>play with. 아스트릿  </div>
                  <div className='txtdetail'>장소-
                  서울 성동구 연무장3길 3   </div>
                  <div className='txtdetail'>기간-
                  2024. 09. 26. ~ 2024. 09. 29.   </div>
               </div>
            </div>

           
            <div className="image-grid">
                {images.map((src, index) => (
                    <img key={index} src={src} alt={`image-${index + 1}`} className="grid-image" />
                ))}
            </div>
        </div>
    );
}

export default Prod4;
