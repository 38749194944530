import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd1() {
  const [views, setViews] = useState(0); // 조회수를 상태로 관리

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd1"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd1"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views); // 조회수를 상태에 저장
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd1");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd1');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd1', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);

  return (
    <div className="exdcon">
      <hr />
      <div className='exdtitle'>길가에 핀 꽃들展</div>
      <div className='exddate'>2018-06-23 연남동 골목</div>
      <div className='funtions'>
    
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
        <img src="../../img/z1.png" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
        &lt; 길가에 핀 꽃들展&gt;은 라트의 첫 번째 기획전시로,
        연남동 골목에서 길거리 전시 형태로 진행되었다. 
    
        현대 사회를 살아가는 우리들을 모두 꽃으로 표현하였으며
        꽃은 각각의 아름다움을 지녔지만, 계절 별로 만개하는 시기가 
        다르다는 점에 주목하였다. 서로 만개하는 시기가 달라 먼저 만개한 
        꽃을 부러워하거나, 아직 만개하지 못한 자신을 자책하는 우리들을 본 
        전시에서는 꽃으로 표현하여 ‘시기는 다르나 모든 꽃은 아름답게 만개할 
        것이다.’라는 의미를 주고자 하였다.
      </div>
      <div className='exdbottom'>
        작가 - 김동규, 이찬규, 전병선, 한동원 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 연남동 골목<br /><br />
        기간 - 2018-06-23<br /><br />
      </div>
    </div>
  );
}

export default Exd1;
