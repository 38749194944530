import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../style/ImageSlider.css';
import CMobileImageSlider from './CustomImageSliderMobile'; 
import CustomImagePCSlider from './CustomImageSliderPC';

const CustomImageSlider = () => {
    const [isMobile, setIsMobile] = useState(true);
    const navigate = useNavigate();
    const location = useLocation(); // 현재 경로 정보를 가져옵니다.

    const handleFlexSlideClick = () => {
        navigate('/exhibition'); // Correct this path if necessary
    };

    // Check for mobile view based on window size
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // '/' 경로일 때만 슬라이더 렌더링
    if (location.pathname !== '/') {
        return null; // '/'가 아닐 경우 아무것도 렌더링하지 않음
    }

    return (
        <div className='none'>
            <div className='sliderexcon'>
                <div className='flexflexslide'>
                    <div className='slidernames' onClick={handleFlexSlideClick}> EXHIBITION</div>
                    <div className='sliderarrow' onClick={handleFlexSlideClick}> 
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 17 30" fill="none">
                            <path d="M17 15C17 15.432 16.8436 15.8122 16.5133 16.1406L2.72904 29.5334C2.43354 29.8445 2.05112 30 1.59918 30C0.695297 30 0 29.326 0 28.4274C0 27.9781 0.191207 27.5979 0.469325 27.3041L13.1237 15L0.469325 2.69585C0.191207 2.40207 0 2.00461 0 1.57258C0 0.673963 0.695297 0 1.59918 0C2.05112 0 2.43354 0.15553 2.72904 0.449309L16.5133 13.8594C16.8436 14.1705 17 14.568 17 15Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className='sliderexplain'> 아티스트의 상상이 현실이 될 수 있도록<br />
                다양한 전시를 기획합니다.</div>
            </div>
            {isMobile ? <CMobileImageSlider /> : <CustomImagePCSlider />}
        </div>
    );
};

export default CustomImageSlider;
