// src/components/TabMenu.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function TabMenu({ selectedTab, setSelectedTab }) {
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    // 전시 상태에 따라 이동할 경로 설정
    if (tab === '전체') {
      navigate('/exhibition', { state: { selectedTab: tab } });
    } else {
      setSelectedTab(tab);
      navigate('/exhibition', { state: { selectedTab: tab } }); // 선택된 탭에 따라 이동
    }
  };

  return (
    <div className="tab-menu">
      {['전체', '현재 전시', '과거 전시', '예정 전시'].map((tab) => (
        <button
          key={tab}
          className={`tab ${selectedTab === tab ? 'active' : ''}`}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
}

export default TabMenu;
