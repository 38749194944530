import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd3() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd3"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd3"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd3");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd3');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd3', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);
  return (
    <div className="exdcon">
    
      <hr />
      <div className='exdtitle'>감정展</div>
      <div className='exddate'>2020-11-06~2020-11-09 인영갤러리</div>
      <div className='funtions'>

        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z3.jpg" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
       이번 전시를 주최한 '작은 예술가들의 모임 3기'는 총 6의 청년 작가로 이루어져 있으며	
        2018년을 시작으로 총 3번째 전시의 문을 여는 작고 소박한 모임이다.
'작은 예술가들의 모임'에서 '작다'는 '부족하다'라는 의미가 있으며 이는 부족하기에
채워질 수 있다는 성장가능성을 나타낸다.

 본 전시는 평소 우리가 중요하게 여기지 않았던 감정 중 어둡고 우울한 감정들에 대해 논한다. 
 부정적인 감정들을 직관적이고 냉철하게 다루며 이러한 감정들의 의미와 중요성에 대해 생각해 보고자 한다.
 
이하 글은 전시회를 준비하며 녹여냈던 생각들이다.
 "감정이란 완전하지 않고 지속되지 않는다. 또한 '감정이란 무엇인가'에 대한 명확하고 
 적확한 정의를 내리기 쉽지 않다. 그러기에 우리는 삶이란 길을 나아가며 
 스스로의 감정을 때때론 숨기곤 한다.
 우리는 부정적이며 우울한 감정들은 마음속 한편에 숨기는 경향이 있다.
 부정적인 감정을 인정하며 사유하지 못한다면 긍정적인 감정의 존재 자체를 
 인지하지 못할 수도 있는데 말이다.“
 감정展을 통해 내면에 감춰져 있는 어둡고 우울한 감정들과 솔직하게 마주하며 
 소중함을 상기시킬 기회가 되길 기원한다.
      </div>
      <div className='exdbottom'>
        작가 - 김덕훈, 김은지, 심건우, 이찬규, 홍세빈  <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 인영갤러리<br /><br />
        기간 - 2020-11-06~2020-11-09<br /><br />
      </div>
    </div>
  );
}

export default Exd3;
