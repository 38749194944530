import React, { useState, useEffect } from 'react';
import TabMenu from '../compo/TabMenu';
import "../style/Exd.css";
import { db } from '../Config'; // Firebase Firestore 초기화
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import Cookies from 'js-cookie';

function Exd8() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [views, setViews] = useState(0);
  const [selectedTab, setSelectedTab] = useState('전체'); // selectedTab 및 setSelectedTab 초기화

  const handleShare = () => {
    const dummyUrl = "https://lart-official.com/exd8"; 
    navigator.clipboard.writeText(dummyUrl).then(() => {
      alert('주소가 복사되었습니다!');
    });
  };

  // Firestore에서 조회수 불러오기
  const fetchViews = async () => {
    const docRef = doc(db, "pageViews", "exd8"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setViews(docSnap.data().views);
    } else {
      await setDoc(docRef, { views: 0 });
    }
  };

  // Firestore의 조회수 증가 함수
  const incrementViewInFirestore = async () => {
    const docRef = doc(db, "pageViews", "exd8");
    await updateDoc(docRef, {
      views: views + 1,
    });
    setViews((prevViews) => prevViews + 1);
  };

  // 조회수 증가 처리 및 쿠키 설정
  const handleViewIncrement = async () => {
    const today = new Date().toISOString().split('T')[0];
    const viewCookie = Cookies.get('viewed_exd8');

    // 쿠키가 없거나 날짜가 다르면 조회수 증가
    if (viewCookie !== today) {
      await incrementViewInFirestore();
      Cookies.set('viewed_exd8', today, { expires: 1 }); // 하루 동안 유효한 쿠키 설정
    }
  };

  useEffect(() => {
    fetchViews(); // Firestore에서 초기 조회수 가져오기
    handleViewIncrement(); // 쿠키 확인 후 조회수 증가
  }, []);
  return (
    <div className="exdcon">
   
      <hr />
      <div className='exdtitle'>나를 스쳐가는 것들에 대하여展</div>
      <div className='exddate'>2023-09-05~2023-09-11 갤러리 아미디 한남</div>
      <div className='funtions'>
       
        <div className='share' onClick={handleShare}>공유하기</div>
      </div>
      <hr />
      <div className="exdimg">
      <img src="../../img/z8.png" alt="전시 이미지" />
      </div>
      <div className='exdexplain'>
      “우리는 삶을 살아가면서 참 많은 존재들을 만납니다.
가족, 친구, 연인, 반려동물처럼 말이죠.
다양한 형태의 존재들과 상호작용을 하며 그 존재의 의미가 
소중해지기도 더 나아가 각별해지기도 합니다.
하지만 현재 우리 주변에 존재하는 것들은 영원하지 않기에 이별의 순간이 늘 찾아옵니다.
당신의 삶엔 어떠한 소중한 존재가 잠시 머물다갔나요?”

&#91;나를 스쳐가는 것들에 대하여&#93;는 삶을 영위하며 잠시 만나는 유형 혹은 무형의 존재들에 
대한 가치를 되돌아보기 위하여 기획되었다. 
현재 우리 주변에 실재하는 존재들은 영원하지 않다는 점에 주목하며, 다양한 관계의 소중함을 
조명하고자 한다. 소중한 존재들과 공유했던 순간들이 예술가의 시선에서 어떠한 형태로 구현되었는지를 
고민해 보며 작품에 담겨있는 시공간으로 들어가 보는 시간이 되기를 기대한다.
      </div>
      <div className='exdbottom'>
        작가 - 강소이, 검정양말, 김도하, 김호세, 로니아또, 박연지, 박종희, 박진아,
         반아, 수린, 응아, 장우경, 정해든, 토찌, 하현주, 해원, 
         injung, JinMin그리고박준우, 이찬규 <br /><br />
        주최/후원 - 라트<br /><br />
        장소 - 갤러리 아미디 한남<br /><br />
        기간 - 2023-09-05~2023-09-11<br /><br />
      </div>
    </div>
  );
}

export default Exd8;
