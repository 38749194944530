import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import "../style/font.css";
import "../style/Topnav.css";

function Topnav() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isMenuOpen && !event.target.closest('.overlay') && !event.target.closest('.hamburger')) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMenuOpen]);

    let currentState = "";
    switch (location.pathname) {
        case "/":
            currentState = "ABOUT";
            break;
        case "/exhibition":
            currentState = "EXHIBITION";
            break;
        case "/project":
            currentState = "PROJECT";
            break;
        default:
            currentState = "";
            break;
    }

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <div className="navcon">
            <div className="pinkcon">
                <Link to="/" className="nav-link">ABOUT</Link>
                <Link to="/exhibition" className="nav-link">EXHIBITION</Link>
                <Link to="/project" className="nav-link">PROJECT</Link>
            </div>
            <div className='imgres'>
                <Link to="/">
                    <div className="toptopimg" />
                </Link>
            </div>
            <div>
            <a href="https://www.instagram.com/lart__official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer" className="topright">
            <div className="topright">
                  
                    </div> 
            </a>
            
            </div>

            <div className={`hamburger ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div className={`overlay ${isMenuOpen ? "open" : ""}`}>
                <div className="pinkcon">
                    <Link to="/" className="nav-link" onClick={toggleMenu}>ABOUT</Link>
                    <Link to="/exhibition" className="nav-link" onClick={toggleMenu}>EXHIBITION</Link>
                    <Link to="/project" className="nav-link" onClick={toggleMenu}>PROJECT</Link>
                </div>
            </div>
        </div>
    );
}

export default Topnav;
